@define-mixin ContentListItemVariant $textUnderlineColor {
    &.ContentListItem:hover {
        & .LinkTextUnderline {
            border-bottom-color: $textUnderlineColor;
        }
    }
}

.ContentListItem {
    @mixin linkOverlay;

    &:hover {
        & .LinkTextUnderline {
            border-bottom: 2px solid var(--primaryHKLighten40Color);
        }
    }
}

.LinkTextUnderline {
    display: inline;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.2s ease;
}

.ImageWrapper {
    margin-bottom: 16px;

    @media (--tablet) {
        margin-bottom: 32px;
    }
}

.Image {
    @mixin aspectRatio 2/3;
}

.Info {
    & .Category {
        @mixin p40;
        display: block;
        margin-bottom: 16px;
    }

    & .TitleWrapper {
        margin-bottom: 16px;
    }

    & .Title {
        @mixin h50;
        @mixin hyphenateWords;
        font-family: var(--serifFontFamily);
        margin: 0;

        @media (--tablet) {
            @mixin h40;
        }
    }

    & .Description {
        @mixin lineClamp 4;
        @mixin p30;
        margin-bottom: 16px;

        @media (--fromTabletToDesktop) {
            @mixin lineClamp 6;
        }

        @media (--tablet) {
            @mixin p20;
            margin-bottom: 24px;
        }
    }
}

.Variant--horizontal {
    display: flex;
    flex-wrap: wrap;

    & .ImageWrapper {
        width: 100%;

        @media (--tablet) {
            lost-column: 6/12;
            margin-bottom: 0;
        }
    }

    & .Info {
        @media (--tablet) {
            lost-column: 1/2;
        }

        & .Category {
            @mixin p20;

            @media (--tablet) {
                margin-bottom: 24px;
            }
        }

        & .TitleWrapper {
            @media (--tablet) {
                margin-bottom: 24px;
            }
        }

        & .Title {
            @mixin h40;

            @media (--tablet) {
                @mixin h30;
            }
        }
    }
}

.Variant--vertical {
    /* todo. Move here from main styles (where this variant is defined as default). */
}

.Variant--verticalNoImage {
    & .ImageWrapper {
        display: none;
    }
}

.Variant--rounded {
    & .Image {
        @mixin themeRoundedCornersSmall;
        overflow: hidden;
    }
}

.Variant--red {
    @mixin ContentListItemVariant var(--primaryHKLighten60Color);
}

.Variant--white {
    @mixin ContentListItemVariant var(--primaryHKLighten40Color);
}

.Variant--dark {
    @mixin ContentListItemVariant var(--primaryHKLighten40Color);
}

.Variant--grey {
    @mixin ContentListItemVariant var(--primaryHKLighten40Color);
}

.Variant--transparent {
    @mixin ContentListItemVariant var(--primaryHKLighten40Color);
}
